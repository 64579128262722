import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBBtn, MDBIcon, MDBTypography, MDBRow, MDBCol, MDBCard } from 'mdb-react-ui-kit';
import Footer from '../components/footer'
import { MdQuiz } from "react-icons/md";
import { MdOutlineMenuBook } from "react-icons/md";
import pdf from "../imgs/cardapio.pdf"
import LoadingScreen from '../components/loading';
import logo from "../imgs/logo.png"
import container1BG from "../imgs/container-1-bg.jpg"

function Index() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (document.readyState === 'complete') {
            setLoading(false);
        } else {
            const handleLoad = () => {
                setLoading(false);
            };

            window.addEventListener('load', handleLoad);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('load', handleLoad);
        }
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <MDBContainer fluid className="p-0 pt-5 d-flex flex-column align-items-center">

                <MDBRow className='mb-4 main-page-title-wrapper'>
                    <MDBCol>
                        <MDBTypography tag='h1' className='text-center mb-2 main-page-title'>Bem vindo a Bifrost!</MDBTypography>
                        <h2 className='main-page-subtitle'>Faça a nossa pesquisa e ganhe uma bebida grátis! Basta responder as perguntas e mostrar a tela final no balcão!</h2>
                    </MDBCol>
                </MDBRow>

                <MDBRow className='ms-3 me-3 containers-wrapper'>
                    <MDBCol md={12} className='mb-2'>
                        <MDBCard className='container-1'>
                            <h1>Dj do Dia</h1>
                            <img src='https://fakeimg.pl/600x400' />
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit risus a volutpat molestie. In vitae nisi eget arcu tristique consequat.</p>
                            <div className='container-bg container-bg-1' />
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md={6} className='mb-2'>
                        <MDBCard className='container-2'>
                            <h1>Bebida do dia</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit risus a volutpat molestie. In vitae nisi eget arcu tristique consequat.</p>
                            <div className='container-bg container-bg-2' />
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md={6} className='mb-2'>
                        <MDBCard className='container-3'>
                            <h1>Promoção do dia</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit risus a volutpat molestie. In vitae nisi eget arcu tristique consequat.</p>
                            <div className='container-bg container-bg-3' />
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md={6} className='d-flex flex-column mt-2'>
                        <a href='/questionario' className='d-flex justify-content-center'>
                            <MDBBtn tag='a' color='warning' className='mb-4 buttons-main'>
                                <MdQuiz /> Faça a pesquisa
                            </MDBBtn>
                        </a>
                    </MDBCol>
                    <MDBCol md={6} className='d-flex flex-column mt-2'>
                        <a href={pdf} target="_blank" className='d-flex justify-content-center'>
                            <MDBBtn tag='a' color='warning' className='mb-4 buttons-main'>
                                <MdOutlineMenuBook /> Confira nosso cardápio
                            </MDBBtn>
                        </a>
                    </MDBCol>
                </MDBRow>
                <img className='mb-4 mt-4 main-page-logo' src={logo} />
            </MDBContainer>
            <div className='main-bg'></div>
        </>
    );
}

export default Index