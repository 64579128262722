import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBBtn, MDBIcon, MDBTypography, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import LoadingScreen from '../components/loading';
import logo from "../imgs/logo.png"
import { MdOutlineMenuBook } from "react-icons/md";
import pdf from "../imgs/cardapio.pdf"
import { FaGlassMartiniAlt } from "react-icons/fa";

function End() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (document.readyState === 'complete') {
            setLoading(false);
        } else {
            const handleLoad = () => {
                setLoading(false);
            };

            window.addEventListener('load', handleLoad);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('load', handleLoad);
        }
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <MDBContainer fluid className="p-0 d-flex flex-column align-items-center justify-content-around" style={{ height: '100svh', overflow: 'hidden' }}>

                <img className='main-page-logo' src={logo} />

                <MDBRow className='mt-5 end-page-title-wrapper'>
                    <MDBCol>
                        <h1 tag='h1' className='mb-2 end-page-title'>Muito obrigado por responder a pesquisa!</h1>
                        <h2 className='mb-4 end-page-subtitle'>basta levar o celular com essa tela para o balcão para ganhar sua bebida grátis!</h2>
                    </MDBCol>
                    <FaGlassMartiniAlt className='mt-4 mb-4 end-glass' />
                </MDBRow>

                <MDBRow className='ms-3 me-3'>
                    <h4 className='mb-3 buttons-title'>Confira nosso cardápio para ter mais opções!</h4>
                    <MDBCol className='d-flex flex-column ms-4 me-4'>
                        <a href={pdf} target="_blank" className='d-flex justify-content-center'>
                            <MDBBtn tag='a' color='warning' className='mb-4 buttons-main'>
                                <MdOutlineMenuBook /> Confira nosso cardápio
                            </MDBBtn>
                        </a>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <div className='main-bg'></div>
        </>
    );
}

export default End