import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for React Router v6
import { MDBContainer, MDBBtn, MDBIcon, MDBTypography, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Footer from '../components/footer'
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter, FaY } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { PiSealQuestion } from "react-icons/pi";
import { CiGlobe } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import { api } from "../controllers/api-controller";
import logo from "../imgs/logo.png"
import LoadingScreen from '../components/loading';

function Questionary() {
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [answers, setAnswers] = useState({});
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [formValid, setFormValid] = useState(false);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleTelephoneChange = (e) => {
        setTelephone(e.target.value);
    };

    useEffect(() => {
        setFormValid(name.trim() !== '' && email.trim() !== '' && telephone.trim() !== '');
    }, [name, email, telephone]);

    const handleNext = () => {
        setCurrentQuestion(currentQuestion + 1);
    };

    const handlePrevious = () => {
        setCurrentQuestion(currentQuestion - 1);
    };

    const handleAnswerChange = (e) => {
        const { name, value } = e.target;
        // Save the answer to the answers state
        setAnswers({ ...answers, [name]: value });

        // Move to the next question automatically
        setCurrentQuestion(currentQuestion + 1);
    };

    const handleTextAreaChange = (e) => {
        const { name, value } = e.target;
        setAnswers({ ...answers, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            const formData = {
                ...answers,
                nome: name,
                email: email,
                telefone: telephone
            };

            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await axios.post(api + '/api/answers', formData, config);
            console.log('Response from backend:', response.data);
            navigate('/final');
        } catch (error) {
            console.error('Error sending data:', error);
        }
    };

    const renderTimelineBalls = () => {
        const balls = [];
        for (let i = 1; i <= 8; i++) {
            balls.push(
                <div
                    key={i}
                    className={`timeline-ball ${i < currentQuestion ? 'previous' : ''} ${i <= currentQuestion ? 'filled' : ''}`}
                    onClick={() => setCurrentQuestion(i)}
                ></div>
            );
        }
        return balls;
    };

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (document.readyState === 'complete') {
            setLoading(false);
        } else {
            const handleLoad = () => {
                setLoading(false);
            };

            window.addEventListener('load', handleLoad);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('load', handleLoad);
        }
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }


    return (
        <>
            <MDBContainer fluid className="p-0 d-flex flex-column align-items-center" style={{ minHeight: '100vh' }}>
                <img className='qa-page-logo' src={logo} />
                {/* Render current question based on currentQuestion state */}
                {currentQuestion === 1 && (
                    <>
                        <MDBContainer className='qa-container'>
                            <MDBRow className='main-page-title-wrapper'>
                                <MDBCol>
                                    <MDBTypography tag='h1' className='mt-2 mb-2 qa-page-title'>Você gosta da música que tocamos no ambiente?</MDBTypography>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow style={{ width: '100%' }}>
                                <MDBCol className='qa-buttons-wrapper'>
                                    <button className='qa-button-l' type="button" name="q1" value="Nao" onClick={handleAnswerChange} > Não </button>
                                    <button className='qa-button-r' type="button" name="q1" value="Sim" onClick={handleAnswerChange} > Sim </button>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </>
                )}
                {currentQuestion === 2 && (
                    <>
                        <MDBContainer className='qa-container'>
                            <MDBRow className='main-page-title-wrapper'>
                                <MDBCol>
                                    <MDBTypography tag='h1' className='mt-2 mb-2 qa-page-title'>Você gosta do nosso cardápio?</MDBTypography>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow style={{ width: '100%' }}>
                                <MDBCol className='qa-buttons-wrapper'>
                                    <button className='qa-button-l' type="button" name="q2" value="Nao" onClick={handleAnswerChange} > Não </button>
                                    <button className='qa-button-r' type="button" name="q2" value="Sim" onClick={handleAnswerChange} > Sim </button>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </>
                )}

                {currentQuestion === 3 && (
                    <MDBContainer className='qa-container'>
                        <MDBRow className='main-page-title-wrapper'>
                            <MDBCol>
                                <MDBTypography tag='h1' className='mt-2 mb-2 qa-page-title'>Você considera nossos preços acessíveis?</MDBTypography>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{ width: '100%' }}>
                            <MDBCol className='qa-buttons-wrapper'>
                                <button className='qa-button-l' type="button" name="q3" value="Nao" onClick={handleAnswerChange} > Não </button>
                                <button className='qa-button-r' type="button" name="q3" value="Sim" onClick={handleAnswerChange} > Sim </button>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}

                {currentQuestion === 4 && (
                    <MDBContainer className='qa-container'>
                        <MDBRow className='main-page-title-wrapper'>
                            <MDBCol>
                                <MDBTypography tag='h1' className='mt-2 mb-2 qa-page-title'>Quais melhorias você acredita que poderiamos ter?</MDBTypography>
                            </MDBCol>
                        </MDBRow>
                        <textarea
                            className='mt-2 qa-textarea'
                            name="q4"
                            rows={6}
                            onChange={handleTextAreaChange}
                        />
                        <MDBRow style={{ width: '100%' }}>
                            <MDBCol className='qa-buttons-wrapper'>
                                <input className='qa-button-r' type="button" value="Próximo" onClick={handleNext} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}

                {currentQuestion === 5 && (
                    <MDBContainer className='qa-container'>
                        <MDBRow className='main-page-title-wrapper'>
                            <MDBCol>
                                <MDBTypography tag='h1' className='mt-2 mb-2 qa-page-title'>Como descreveria sua experiência geral aqui?</MDBTypography>
                            </MDBCol>
                        </MDBRow>
                        <textarea
                            className='mt-2 qa-textarea'
                            name="q5"
                            rows={6}
                            onChange={handleTextAreaChange}
                        />
                        <MDBRow style={{ width: '100%' }}>
                            <MDBCol className='qa-buttons-wrapper'>
                                <input className='qa-button-r' type="button" value="Próximo" onClick={handleNext} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}
                {currentQuestion === 6 && (
                    <MDBContainer className='qa-container'>
                        <MDBRow className='main-page-title-wrapper'>
                            <MDBCol>
                                <MDBTypography tag='h1' className='mt-2 mb-2 qa-page-title'>Você recomendaria a Bifrost para um amigo?</MDBTypography>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{ width: '100%' }}>
                            <MDBCol className='qa-buttons-wrapper'>
                                <button className='qa-button-l' type="button" name="q6" value="Nao" onClick={handleAnswerChange} > Não </button>
                                <button className='qa-button-r' type="button" name="q6" value="Sim" onClick={handleAnswerChange} > Sim </button>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}

                {currentQuestion === 7 && (
                    <MDBContainer className='qa-container'>
                        <MDBRow className='main-page-title-wrapper'>
                            <MDBCol>
                                <MDBTypography tag='h1' className='mt-2 mb-2 qa-page-title'>Você gosta da música que tocamos no ambiente?</MDBTypography>
                            </MDBCol>
                        </MDBRow>
                        <textarea
                            className='mt-2 qa-textarea'
                            name="q7"
                            rows={6}
                            onChange={handleTextAreaChange}
                        />
                        <MDBRow style={{ width: '100%' }}>
                            <MDBCol className='qa-buttons-wrapper'>
                                <input className='qa-button-r' type="button" value="Próximo" onClick={handleNext} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}

                {currentQuestion === 8 && (
                    <MDBContainer className='qa-container'>
                        <MDBRow className='main-page-title-wrapper'>
                            <MDBCol>
                                <MDBTypography tag='h1' className='mt-2 mb-2 qa-page-title'>Por favor, informe seu nome, e-mail e telefone:</MDBTypography>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{ width: '100%' }}>
                            <MDBCol className='qa-inputs-wrapper'>
                                <input type="text" placeholder="Nome" value={name} onChange={handleNameChange} />
                                <input type="email" placeholder="E-mail" value={email} onChange={handleEmailChange} />
                                <input type="tel" placeholder="Telefone" value={telephone} onChange={handleTelephoneChange} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{ width: '100%' }}>
                            <MDBCol className='qa-buttons-wrapper'>
                                <input className='qa-button-next' type="button" value="Enviar" onClick={handleSubmit} disabled={!formValid} />
                            </MDBCol>
                        </MDBRow>
                        <div className="timeline">
                            {renderTimelineBalls()}
                        </div>
                    </MDBContainer>

                )}


                {/* <button onClick={handlePrevious} disabled={currentQuestion === 1}>Previous</button>
                {currentQuestion < 7 ? (
                    <button onClick={handleNext}>Next</button>
                ) : (
                    <button onClick={handleSubmit}>Submit</button>
                )} */}

                {/* Timeline component */}
            </MDBContainer>
            <div className='main-bg'></div>
        </>
    );
}

export default Questionary;
